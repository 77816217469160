import config from '../config'

export default {
  init: () => {
    const gaScript = document.createElement('script')
    gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + config.ga)
    gaScript.setAttribute('async', true)

    window.dataLayer = window.dataLayer || []
    function gtag () {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', config.ga, { anonymize_ip: true })

    document.body.appendChild(gaScript)
  },
  stop: () => {
    window.dataLayer = []
    // Remove GTM scripts
    Array.from(document.getElementsByTagName('script'))
      .filter(script => script.src.includes('googletagmanager'))
      .map(script => script.parentNode.removeChild(script))
  }
}
