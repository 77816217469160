import analytics from './analytics'
import cookies from 'js-cookie'

export default {
  init: () => {
    const cookieSlug = 'consent-allowed'
    const cookieBarDisplay = '-display'
    const cookieBar = document.querySelector('.cookieBar')
    const cookieConsentTime = 365 // in days

    // If cookie consent doesn't exist, display cookie bar
    if (!cookies.get(cookieSlug)) {
      cookieBar.classList.add(cookieBarDisplay)

      // When user accept cookies, cookie bar closes
      cookieBar.querySelector('#cookieBar__ok').addEventListener('click', () => {
        cookies.set(cookieSlug, true, { expires: cookieConsentTime }) // store cookie consent to true
        cookieBar.classList.remove(cookieBarDisplay)
      })
    }

    // If user doesn't refuse cookies, run analytics
    if (cookies.get(cookieSlug) !== 'false') {
      analytics.init()
    }

    // Add event listener to refuse cookies
    const refuseCookiesBtn = document.querySelector('#refuseCookies')

    if (refuseCookiesBtn) {
      const cookieRefusedLabel = 'Cookies refusés'

      if (cookies.get(cookieSlug) === 'false') {
        refuseCookiesBtn.innerHTML = cookieRefusedLabel
        refuseCookiesBtn.setAttribute('disabled', 'disabled')
      }

      refuseCookiesBtn.addEventListener('click', () => {
        // remove all cookies
        Object.keys(cookies.get()).forEach(key => cookies.remove(key))
        // remove analytic tags
        analytics.stop()
        // store cookie consent to false
        cookies.set(cookieSlug, false, { expires: cookieConsentTime })
        refuseCookiesBtn.innerHTML = cookieRefusedLabel
        refuseCookiesBtn.setAttribute('disabled', 'disabled')
      })
    }
  }
}
